import { graphql, useStaticQuery ,Link} from "gatsby"
import React from "react"
import { Navbar, Nav, Image, NavDropdown, Button ,Container,Row} from "react-bootstrap"
import { FaBars } from "react-icons/fa"
import { IconContext } from "react-icons"
import Logo from "../../images/header-logo-petrachor.svg"
import "./navigation.less"

const NavigationBar = ({ props }) => {
  const data = useStaticQuery(graphql`
    query NavbarQuery {
      strapiNavbar {
        NavItem 
      }
    }
  `)
  return (
    <Container>
      <Row>
        <Navbar className="navbar-container" expand="lg">
          <Navbar.Brand href="/">
            <Image src={Logo} fluid />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <IconContext.Provider value={{ className: "toggler-btn" }}>
              <div>
                <FaBars style={{ color: "#B86B0C", border: 0 }} />
              </div>
            </IconContext.Provider>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end text-center ml-auto">
            <Nav>
              {data.strapiNavbar?.NavItem?.map((item, index) => {
                if (item.Title) {
                  return (
                    <Nav.Link key={item.id} href={item.Link}>
                      {item.Title}
                    </Nav.Link>
                  )
                }
                if (item.DropdownTitle) {
                  return (
                    <NavDropdown key={item.id} title={item.DropdownTitle} id="basic-nav-dropdown">
                      {item?.DropdownList?.map((item, index) => {
                        const url=item.Link;
                        return (
                          <Nav.Link key={item.id}
                           href={url}>
                            {item.Title}
                          </Nav.Link>
                        )
                      })}
                    </NavDropdown>
                  )
                }
              })}
              <a href="https://app.petrachor.com/" className="btn nav-button">Launch App</a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
    </Container>
  )
}

export default NavigationBar

export const NavBarBg = ({ children }) => {
  return <div class="navbar-bg">{children}</div>
}
